<template>
    <multiselect @search-change="handleSearch" :label="this.label ? this.label : 'name'"
        :track-by="this.trackBy ? this.trackBy : 'name'"
        :select-label="''"
        :deselect-label="''"
        :options="(this.optionsData.length > 0) ? this.optionsData : (this.options != undefined ? this.options : [])"
        v-model="selected" :placeholder="placeholder ? placeholder : `type to search`" />
</template>
<script>

export default {
    components: {
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String
        },
        setDefault:{
            type:Boolean,
            default:true,
        },
        label: {
            type: String,
        },
        baseUrl: {
            type: String
        },
        trackBy: {
            type: String,
        },
        value: {
            type: Object,
        },
        options: {
            required: false
        }
    },
    data() {
        return {
            // baseUrl: "/api/wms/v1/master/warehouses",
            typedValue: "",
            timeOuts: null,
            optionsData: [],
            selected: this.value ? this.value : {}
        };
    },
    methods: {
        customSku({ code, name }) {
            return `${code} - ${name}`;
        },
        handleSearch(v) {
            this.typedValue = v;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.get, 400);
        },
        get() {
            // this.$vs.loading();
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "id",
                        sort: "asc",
                        query: this.typedValue,
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        if (this.label == "codename") {
                            this.optionsData = resp.data.records.map(record => ({
                                ...record,
                                codename: this.customSku(record)
                            }));
                        }else{
                            this.optionsData = resp.data.records
                        }
                        if (this.setDefault){
                            if (!this.value && resp.data.default) {
                            this.selected = resp.data.default;
                            this.$emit("selected", resp.data.default);
                        }
                        }
                    } else {
                        this.optionsData = [];
                        console.log(resp.data);
                    }
                });
        }

    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        if (this.baseUrl != "" && this.baseUrl != undefined) {
            this.get();
        } else if (this.options.length > 0) {
            this.optionsData = this.options
        }
    }
};
</script>